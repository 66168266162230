<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_countInstSearch" />
      <v-form ref="form">
        <v-container fluid>
          <v-row class="search-row">
            <div class="search-autocomplete first-search-item">
              <!-- 取引先-->
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                :rules="[rules.inputRequired]"
                class="txt-single"
                persistent-hint
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <div class="search-autocomplete">
              <!-- 棚卸方式 -->
              <v-autocomplete
                dense
                v-model="inventoryMethodSelected"
                :items="inventoryMethodList"
                :label="$t('label.lbl_invatoryMethod')"
                :hint="setMethod()"
                :rules="[rules.inputRequired]"
                class="txt-single"
                persistent-hint
                @change="changeInventryMethodList"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <div class="date-style">
              <!-- 棚卸日-->
              <v-text-field
                outlined
                dense
                v-model="inventoryDate"
                :label="$t('label.lbl_invatoryDate')"
                @change="changeDate"
                :rules="[rules.yyyymmdd, rules.dateCheck]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
                :disabled="activateCallender()"
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>

            <div class="calender-btn-area">
              <!-- 棚卸日カレンダー-->
              <v-menu
                v-model="inventoryDateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
                :disabled="activateCallender()"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" :disabled="activateCallender()" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="inventoryDateCal"
                  @input="inventoryDateMenu = false"
                  :allowed-dates="allowedDates"
                  max="2099-12-31"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                :disabled="activateCallender()"
                @click="prevDate"
                text
                x-small
              >
                <!-- 前日-->
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                :disabled="activateCallender()"
                @click="nextDate"
                text
                x-small
              >
                <!-- 次日-->
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 倉庫 -->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="warehouseSelected"
                :items="warehouseList"
                :label="$t('label.lbl_warehouse')"
                :hint="setWarehouse()"
                class="txt-single"
                persistent-hint
                :rules="[rules.inputRequired]"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 期待値 -->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="expectedValueSelected"
                :items="expectedValueList"
                :label="$t('label.lbl_expectedValue')"
                :hint="setExpectedValue()"
                class="txt-single"
                persistent-hint
                :rules="[rules.inputRequired]"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require">*</span>
          </v-row>
          <v-row>
            <!--ボタン領域-->
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン -->
                <v-btn color="primary" class="api-btn" @click="getApiSearch(true)">{{
                  $t("btn.btn_search")
                }}</v-btn>
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
              <div class="btn-area">
                <!--計画登録ボタン-->
                <v-btn color="primary" class="api-btn" :disabled="displayFlg" @click="planAdd()">{{
                  $t("btn.btn_sheAdd")
                }}</v-btn>
              </div>
            </div>
          </v-row>
          <v-row> </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listDataSpecial"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :ripple="false"
          disable-filtering
          disable-pagination
          :hide-default-footer="true"
          height="620px"
          group-by="locationGroup"
          ref="table"
        >
          <!-- ヘッダーチェックボックス -->
          <template v-slot:[`header.check2`]="{ header }">
            <input
              type="checkbox"
              :checked="isAllSelected2"
              :value="header.check2"
              @click="selectAllCheck2(header)"
              style="transform: scale(2)"
              v-if="!inventoryFlg"
            />
          </template>
          <!-- グループチェックボックス -->
          <template v-slot:[`group.header`]="{ group, isOpen, toggle, items }">
            <td colspan="5">
              <v-container fluid>
                <v-btn v-if="inventoryFlg" @click="toggle" x-medium icon :ref="group">
                  <v-icon v-if="isOpen">mdi-minus</v-icon>
                  <v-icon v-else>mdi-plus</v-icon>
                </v-btn>
                <input
                  type="checkbox"
                  :value="items.check"
                  v-model="items.check"
                  @click="selectGroupAllCheck(items, group)"
                  style="transform: scale(2)"
                  class="accordion"
                  :id="'group' + group"
                />
                {{ $t("label.lbl_locaGroup") }} {{ group }}
              </v-container>
            </td>
          </template>
          <!-- ヘッダーチェックボックス -->
          <template v-slot:[`header.check`]="{ header }">
            <input
              type="checkbox"
              :checked="isAllSelected"
              :value="header.check"
              @click="selectAllCheck"
              style="transform: scale(2)"
              v-if="inventoryFlg"
            />
          </template>
          <!-- ヘッダーロケ -->
          <template v-slot:[`header.location`]="{ header }">
            <div v-if="inventoryFlg">
              {{ $t("label.lbl_location") }}
              {{ header.location }}
            </div>
            <div v-else></div>
          </template>
          <!-- ヘッダー担当者 -->
          <template v-slot:[`header.person`]="{ header }">
            <div v-if="inventoryFlg">
              {{ $t("label.lbl_popDestinationNm") }}
              {{ header.person }}
            </div>
            <div v-else></div>
          </template>
          <!-- 一覧チェックボックス -->
          <template v-slot:[`item.check`]="{ item }">
            <input
              type="checkbox"
              v-model="item.check"
              :value="listCheckbox"
              style="transform: scale(2)"
            />
          </template>
        </v-data-table>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <!--アラートポップアップ -->
      <v-dialog v-model="alertDialog" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ alertMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="okClicked"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: appConfig.SCREEN_ID.P_CNT_001,
  components: {
    Loading,
    sideMenu,
    NavBar,
    SimpleDialog,
    ConfirmDialog,
  },
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    u_10: false,
    // 全選択チェックボックス
    isAllSelected: false,
    isAllSelected2: false,
    // グループ選択
    isGroupSelected: false,
    groupChek: [],
    checkedGroup: [],
    // 一覧選択チェックボックス
    listCheckbox: [1, 2],
    // 棚卸日
    inventoryDateCal: "",
    inventoryDate: "",
    // 取引先プルダウン
    suppliersSelected: "",
    supplierList: [],
    // 棚卸方式プルダウン
    inventoryMethodSelected: "",
    inventoryMethodList: [],
    inventoryDateMenu: false,
    // 倉庫プルダウン
    warehouseSelected: "",
    warehouseList: [],
    // メニュー
    openMenu: null,
    // 編集用チェックボックスリスト
    chengeListCheckbox: [],
    listCheckboxBtn: [],
    // 出荷予定Toカレンダー
    dateMenuTo: false,
    // ローケーションSidを格納する
    locationSidList: [],
    // 荷動き品番用 API用チェックリスト(前画面から受け取る)
    checkListForApi: [],
    // アラートダイアログ
    alertDialog: false,
    alertMessage: "",
    // 確認メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    // ロケ表示フラグ
    inventoryFlg: false,
    // 初期データ
    defaultData: [],
    headerItems: [
      // ロケグループ(ヘッダー表示用)
      // 選択
      {
        text: "選択",
        value: "check2",
        width: "2%",
        align: "center",
        sortable: false,
      },
      // これがないとヘッダーにロケーショングループという文言が表示されない
      {
        text: i18n.tc("label.lbl_locaGroup"),
        value: "dummy",
        width: "4%",
        align: "center",
        sortable: false,
      },
      // 選択
      {
        text: "選択",
        value: "check",
        width: "7%",
        align: "center",
        sortable: false,
      },
      // ロケグループ
      {
        text: i18n.tc("label.lbl_locaGroup"),
        value: "locationGroup",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // ロケーション
      {
        text: i18n.tc("label.lbl_location"),
        value: "location",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // 担当者
      {
        text: i18n.tc("label.lbl_popDestinationNm"),
        value: "person",
        width: "5%",
        align: "left",
        sortable: false,
      },
    ],
    // 総件数
    totalCount: 0,
    // ボタンの表示切り替え
    displayFlg: true,
    // 一覧
    inputList: [],
    // 期待値
    expectedValueSelected: "",
    expectedValueList: [],
    searchExpectedValue: "",
    parameterMap: new Map(),
    availableMaxCheckCount: 1000,

    // 入力チェック
    rules: {
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      /**
       * 日付の当日以降チェック
       * @param {*} value チェック対象日付（yyyy-MM-dd）
       */
      dateCheck(value) {
        if (dateTimeHelper.isDateAfterTheDay(value)) {
          // 当日以降の場合は処理なし
          return true;
        } else {
          // 当日より前日付の場合はエラー
          return i18n.tc("check.chk_DateAfterTheDay");
        }
      },
    },
  }),
  methods: {
    getInitData() {
      this.loadingCounter = 1;
      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC()
      );
      // 倉庫一覧
      const warehouseBizList = getParameter.getWarehouseBiz();
      // 棚卸方式
      const methodList = getParameter.getCodeMst(appConfig.CODETYPE.INVENTORY_MET);

      const inventoryAvailableMaxCheckCount = this.getCodeMst(
        appConfig.CODETYPE.INVENTORY_AVAILABLE_MAX_CHECK_COUNT
      );

      Promise.all([clientList, warehouseBizList, methodList, inventoryAvailableMaxCheckCount])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];
          this.warehouseList = result[1];
          this.inventoryMethodList = result[2];
          // 取引先が1件の場合、取引先を自動選択する
          this.supplierList.length == 1
            ? (this.suppliersSelected = this.supplierList[0].value)
            : "";
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    /**
     * コードマスタ取得
     */
    getCodeMst(codeType) {
      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();

      // 検索条件などのパラメータを設定する
      config.params.isMst = "0";
      config.params.codeType = codeType;

      // console.debug("GetParameter getCodeMst Url", codeType, config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_CODE, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("GetParameter getCodeMst Response", codeType, jsonData);

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              jsonData.resIdv.codeDefinition.forEach((row) => {
                list.push(row);
              });
              switch (codeType) {
                case "1024":
                  this.availableMaxCheckCount = parseInt(list[0].codeInfoList[0].item01);
                  break;
                default:
              }
              resolve();
              // エラー時
            } else {
              reject(jsonData.resCom.resComMessage);
            }
          })
          .catch((resolve) => {
            console.error("GetParameter getCodeMst Err", resolve);
            reject(messsageUtil.getMessage("P-999-999_999_E"));
          });
      });
    },
    // 期待値を取得
    expectedValue() {
      // 画面クリア時の場合
      if (this.clearFlg) {
        this.clearFlg = false;
        return;
      }
      let list = [
        { value: "0", text: "なし" },
        { value: "1", text: "あり" },
      ];
      this.expectedValueList = list;

      if (this.inventoryMethodSelected != "03" && this.inventoryMethodSelected != "05") {
        let value = "1";
        let list2 = list.filter((item) => item.value !== value);
        this.expectedValueList = list2;
      }
      this.expectedValueSelected = this.expectedValueList[0].value;
    },
    /**
     * 最新のフォーマットを取得する
     */
    // getExpectedValue() {
    //   let tmpList = JSON.parse(JSON.stringify(this.expectedValueList));
    //   var index = tmpList.indexOf("02");
    //   tmpList.splice(index, 1);
    //   return tmpList[0].value;
    // },
    // カレンダーの活性化
    activateCallender() {
      if (this.inventoryMethodSelected == "03" || this.inventoryMethodSelected == "04") {
        return false;
      } else {
        return true;
      }
    },
    /**
     * グループ別全選択チェックボックス処理
     */
    selectGroupAllCheck(item, group) {
      if (!this.inventoryFlg) {
        let obj = this.listCheckboxBtn.filter((value) => {
          return value[0].locationGroup == item[0].locationGroup;
        });
        if (!obj[0]) {
          this.listCheckboxBtn.push(item);
        } else {
          if (!obj.check) {
            this.listCheckboxBtn.splice(this.listCheckboxBtn.indexOf(obj[0]), 1);
          }
        }
      } else {
        this.listCheckbox = [];
        this.listCheckbox = true;
        // グループにチェックが入ったら
        if (!item.check) {
          for (var j = 0; j < this.inputList.length; j++) {
            // 選択されたロケーショングループとinputListのロケーショングループが同じ場合チェックを入れる
            if (group == this.inputList[j].locationGroup) {
              this.inputList[j].check = true;
              // 既にチェックが入っていて、ロケグループが選択されたままの場合チェックを入れたままにする
            } else if (this.inputList[j].check) {
              this.inputList[j].check = true;
            } else {
              // 違う場合は、チェックを外す
              this.inputList[j].check = false;
            }
          }
          // グループのチェックが外されたら
        } else {
          for (var m = 0; m < this.inputList.length; m++) {
            if (group == this.inputList[m].locationGroup && item.check)
              this.inputList[m].check = false;
          }
          item.check = false;
        }
      }
    },
    /**
     * 全選択チェックボックス処理
     */
    selectAllCheck() {
      if (!this.isAllSelected) {
        this.listCheckbox = [];
        this.listCheckbox = true;
        this.isAllSelected = true;
        for (var i = 0; i < this.inputList.length; i++) {
          this.inputList[i].check = true;
        }
      } else {
        this.listCheckbox = [];
        this.listCheckbox = false;
        for (var j = 0; j < this.inputList.length; j++) {
          this.inputList[j].check = false;
        }
        this.isAllSelected = false;
      }
    },
    /**
     * 全選択チェックボックス処理
     */
    selectAllCheck2() {
      let elements = document.querySelectorAll("[id^='group']");
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].checked == this.isAllSelected2) {
          elements[i].click();
        }
      }
      if (!this.isAllSelected2) {
        this.isAllSelected2 = true;
      } else {
        this.isAllSelected2 = false;
      }
    },

    // 検索ボタン押下処理
    getApiSearch() {
      // 入力チェック結果フラグ
      let inputCheckResult = false;
      // チェックボタンリセット
      this.isAllSelected = false;
      this.isAllSelected2 = false;
      this.listCheckboxBtn = [];

      /**
       * 登録画面からの遷移の場合、入力チェックを実施するとvalidateが定義されていないと
       * エラーがでるので回避するため、荷動きの時は日付チェックが必要なためこのようなコードとなっている。
       */
      if (this.$route.params.suppliersSelected && this.inventoryMethodSelected !== "03") {
        // 登録画面からの遷移の場合、入力チェックは実施しない。
        inputCheckResult = true;
      } else {
        // 上記外の場合はチェックを行う。
        inputCheckResult = this.$refs.form.validate();
      }

      // 入力チェックを実施
      if (inputCheckResult) {
        // 計画登録ボタン活性化
        this.displayFlg = false;
        // ローディング画面表示ON
        this.loadingCounter = 1;
        const config = this.$httpClient.createGetApiRequestConfig();
        // 営業所SID（ログインユーザ情報）
        config.params.officeSid = sessionStorage.getItem("sales_office_sid");
        // 取引先SID
        config.params.clientSid = this.suppliersSelected;
        // 倉庫SID
        config.params.warehouseSid = this.warehouseSelected;

        // 棚卸方式01：全棚、02：循環棚卸、03：荷動
        // 荷動きが選択されている場合
        if (this.inventoryMethodSelected === "03") {
          config.params.stockCheckDiv = this.inventoryMethodSelected;
          config.params.yyyymmddHhmmss = dateTimeHelper.convertUTC(this.inventoryDate);
          this.inventoryFlg = true;
        } else if (this.inventoryMethodSelected === "04") {
          config.params.stockCheckDiv = this.inventoryMethodSelected;
          config.params.yyyymmddHhmmss = dateTimeHelper.convertUTC(this.inventoryDate);
          this.inventoryFlg = false;
        } else if (this.inventoryMethodSelected === "05") {
          config.params.stockCheckDiv = this.inventoryMethodSelected;
          this.inventoryFlg = true;
        } else {
          config.params.stockCheckDiv = this.inventoryMethodSelected;
          this.inventoryFlg = false;
        }
        // 検索条件期待値
        this.searchExpectedValue = this.expectedValueSelected;

        // console.debug("getApiSearch() Config", config);
        return new Promise((resolve, reject) => {
          this.$httpClient
            .secureGet(appConfig.API_URL.CNT_CHECKTARGET, config)
            .then((response) => {
              // console.debug("getApiSearch() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));

              // 正常時
              if (jsonData.resCom.resComCode == "000") {
                const list = [];
                let locationGroupMap = new Map();
                const locationGroupSet = new Set();

                jsonData.resIdv.stockCheckTarget.forEach((row) => {
                  let resp = {
                    warehouseSid: row.warehouseSid, //倉庫SID
                    warehouseName: row.warehouseName, //倉庫名
                    locationSid: row.locationSid, //ロケーションSID
                    location: row.locationNo, //★ロケーションNo
                    locationGroup: row.locationGroup, //★ロケグループ
                    userSid: row.userSid, //担当者SID
                    person: row.userName, //★担当者名
                    clientSid: this.suppliersSelected, //取引先SID
                    officeSid: row.officeSid, //営業所SID
                    check: false,
                  };
                  // 全棚/循環棚卸の場合は、ロケーショングループのみ画面にレンダリングする
                  if (!this.inventoryFlg) {
                    if (!locationGroupSet.has(row.locationGroup)) {
                      locationGroupMap.set(row.locationGroup, [resp]);
                      locationGroupSet.add(row.locationGroup);
                      list.push(resp);
                    } else {
                      let obj = locationGroupMap.get(row.locationGroup);
                      obj.push(resp);
                    }
                  } else {
                    list.push(resp);
                  }
                });
                this.inputList = list;
                this.parameterMap = locationGroupMap;

                if (!this.inventoryFlg) {
                  // 描画完了時に実行
                  this.$nextTick(() => {
                    // <v-data-table ref="table" ...>で設定したオブジェクトを取得
                    let table = this.$refs.table;
                    // v-data-table内のアコーディオンの開閉情報のキーの一覧を取得
                    let keys = Object.keys(table.$vnode.componentInstance.openCache);
                    // 取得したキーの一覧へ
                    keys.forEach((x) => {
                      // アコーディオンの開閉情報を閉じている状態に変更
                      table.$vnode.componentInstance.openCache[x] = false;
                    });
                    if (this.inventoryMethodSelected === "01") {
                      this.selectAllCheck2();
                    }
                  });
                } else {
                  this.$nextTick(() => {
                    // <v-data-table ref="table" ...>で設定したオブジェクトを取得
                    let table = this.$refs.table;
                    // v-data-table内のアコーディオンの開閉情報のキーの一覧を取得
                    let keys = Object.keys(table.$vnode.componentInstance.openCache);
                    // 取得したキーの一覧へ
                    keys.forEach((x) => {
                      // アコーディオンの開閉情報を閉じている状態に変更
                      table.$vnode.componentInstance.openCache[x] = true;
                    });
                  });
                  // 処理なし
                }
                resolve(response);

                // エラー時
              } else {
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.firstPageFlag = true;

                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },
    // 計画登録ボタン押下**修正・削除画面へ遷移**
    planAdd() {
      if (this.$refs.form.validate()) {
        var checkList = [];

        if (!this.inventoryFlg) {
          for (var i = 0; i < this.listCheckboxBtn.length; i++) {
            if (this.listCheckboxBtn[i].check) {
              checkList = checkList.concat(
                this.parameterMap.get(this.listCheckboxBtn[i][0].locationGroup)
              );
            }
          }
        } else {
          // チェックした行

          for (var j = 0; j < this.inputList.length; j++) {
            if (this.inputList[j].check) {
              checkList.push(this.inputList[j]);
            }
          }
        }

        // チェックが１つもない場合
        if (checkList.length == 0) {
          this.alertDialog = true;
          this.alertMessage = messsageUtil.getMessage("P-EXT-003_001_E");
        } else {
          if (this.inventoryFlg) {
            // if (!this.checkMaxSelectLocations(checkList.length)) {
            //   return;
            // }
            for (let i = 0; i < checkList.length; i++) {
              let locaSid = {
                locationSid: checkList[i].locationSid,
              };
              this.locationSidList.push(locaSid);
            }
          } else {
            for (let i = 0; i < checkList.length; i++) {
              this.locationSidList = this.locationSidList.concat(checkList[i].locationSid);
            }
          }

          this.$router.push({
            name: appConfig.SCREEN_ID.P_CNT_002,
            params: {
              checkList: checkList,
              officeSid: checkList[0].officeSid,
              warehouseSid: this.warehouseSelected,
              locationSidList: this.locationSidList,
              // 検索日付
              date: dateTimeHelper.convertUTC(this.inventoryDate),
              // 検索条件を渡す
              supplier: this.suppliersSelected, //取引先
              method: this.inventoryMethodSelected, //棚卸方式
              inventoryDate: this.inventoryDate, //棚卸日
              warehouse: this.warehouseSelected, //倉庫
              expectedValue: this.searchExpectedValue, //期待値
              clientSid: checkList[0].clientSid, //取引先Cd
              name: "CntInstSearch",
            },
          });
        }
      }
    },
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      if (this.$route.params.suppliersSelected && !this.clearFlg) {
        this.suppliersSelected = this.$route.params.suppliersSelected;
        this.warehouseSelected = this.$route.params.warehouse;
        this.inventoryMethodSelected = this.$route.params.method;
        this.expectedValueSelected = this.$route.params.dispOnOffFlg;
        this.getApiSearch();
      } else {
        this.suppliersSelected = "";
        this.inputList = [];
      }
      this.inventoryDate = dateTimeHelper.convertJST();
      this.inventoryDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.getInitData();
    },
    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },
    /**
     * 棚卸日を－１日します。
     */
    prevDate() {
      let today = dateTimeHelper.convertJST();

      if (this.inventoryDateCal == null) {
        return;
      }
      if (this.inventoryDateCal == dateTimeHelper.dateCalc(today)) {
        return;
      }
      let date = new Date(this.inventoryDateCal);
      this.inventoryDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },
    /**
     * 棚卸日を＋１日します。
     */
    nextDate() {
      if (this.inventoryDateCal == null) {
        return;
      }
      let date = new Date(this.inventoryDateCal);
      this.inventoryDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },
    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    // 日付入力
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.inventoryDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.inventoryDateCal = null;
      }
    },
    // 警告ダイアログ1閉じる
    okClicked() {
      this.alertDialog = false;
    },
    /**
     * チェックボックス取得処理
     */
    onInputCheck(item) {
      if (item.check) {
        for (let i = 0; i < this.inputList.length; i++) {
          this.inputList[i].value = true;
        }
      } else {
        for (let i = 0; i < this.inputList.length; i++) {
          this.inputList[i].check = false;
          this.inputList[i].value = false;
        }
      }
    },
    // 取引先プルダウンセット

    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },
    // 棚卸方式セット
    setMethod() {
      for (var i = 0; i < this.inventoryMethodList.length; i++) {
        if (this.inventoryMethodList[i].value == this.inventoryMethodSelected) {
          return this.inventoryMethodList[i].name;
        }
      }
    },
    // 倉庫セット
    setWarehouse() {
      for (var i = 0; i < this.warehouseList.length; i++) {
        if (this.warehouseList[i].value == this.warehouseSelected) {
          return this.warehouseList[i].name;
        }
      }
    },
    // 期待値セット
    setExpectedValue() {
      for (var i = 0; i < this.expectedValueList.length; i++) {
        if (this.expectedValueList[i].value == this.expectedValueSelected) {
          return this.expectedValueList[i].name;
        }
      }
    },

    /**
     * 棚卸方式を変更した時のメソッド.
     * 明細がある場合、クリア処理を行う。
     */
    changeInventryMethodList() {
      // 検索結果をクリアする。
      if (this.inputList.length == 0) {
        // 明細リストが0件の場合処理なし
      } else {
        // リストをクリアする。
        this.inputList.splice(0, this.inputList.length);
        // チェックボタンリセット
        this.isAllSelected = false;
        this.isAllSelected2 = false;
      }
      // 棚卸日を当日にする。
      this.inventoryDate = dateTimeHelper.toStringNowYYYY_MM_DD1();
    },
    /**
     * カレンダーの当日以降のみ選択できるようにするメソッド
     * @param {*} val カレンダーの日付
     */
    allowedDates(val) {
      return dateTimeHelper.isDateAfterTheDay(val);
    },
    /**
     * ロケーション選択上限チェックをする
     */
    checkMaxSelectLocations(checkCount) {
      if (this.inventoryMethodSelected == "05" && this.availableMaxCheckCount < checkCount) {
        this.alertDialog = true;
        this.alertMessage = messsageUtil.getMessage("P-CNT-006_005_E", [
          this.availableMaxCheckCount,
        ]);
        return false;
      }
      return true;
    },
    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // 変更検知用の設定
      this.inventoryDateCal = "";
      // クリアフラグ
      this.clearFlg = true;
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    inventoryDateCal: {
      handler(val) {
        this.inventoryDate = this.formatDate(val);
      },
      deep: true,
    },
    inventoryMethodSelected(newValue) {
      if (newValue || null) {
        this.inventoryMethodSelected = newValue;
        this.expectedValue();
      } else {
        this.inventoryMethodSelected = newValue;
        this.expectedValue();
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
.from_to {
  font-weight: bold;
}
.toatlNum {
  margin-left: 10px;
}
.link {
  text-decoration: none;
}
.accordion {
  margin: 15px;
}

#listDataSpecial {
  &.v-data-table--fixed-header ::v-deep {
    tr:nth-child {
      background-color: #eee;
      height: 2rem;
    }
    // グループヘッダー
    .v-row-group__header {
      background-color: #dde5f0;
    }
    // データがありません時のスタイル
    .v-data-table__empty-wrapper {
      background-color: #dde5f0;
    }

    th {
      font-size: large !important;
      color: black;
      background: #effad9;
      height: 2.5rem !important;
      &.asta::after {
        content: "*" !important;
        color: red;
      }

      &:not(:nth-child(0)) {
        &::before {
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    //テーブルデータ部分
    td {
      font-size: large;
    }
  }
}
</style>
